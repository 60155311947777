import img_1 from "./assets/Scrolls/scroll1/OFF.jpg";
import img_2 from "./assets/Scrolls/scroll1/Daylight.jpg";
import img_3 from "./assets/Scrolls/scroll1/Evening.jpg";
import img_4 from "./assets/Scrolls/scroll1/Bedtime.jpg";
import img_5 from "./assets/Scrolls/scroll1/Night.jpg";

import img_1s2 from "./assets/Scrolls/scroll2/before.jpg";
import img_2s2 from "./assets/Scrolls/scroll2/after.jpg";

import img_1s3 from "./assets/Scrolls/scroll3/before.jpg";
import img_2s3 from "./assets/Scrolls/scroll3/after.jpg";

import img_1s4 from "./assets/Scrolls/scroll4/before.jpg";
import img_2s4 from "./assets/Scrolls/scroll4/after.jpg";

import img_1s5 from "./assets/Scrolls/scroll5/before.jpg";
import img_2s5 from "./assets/Scrolls/scroll5/after.jpg";

export const scroll1 = [
    {
        lable:'Off',
        image:img_1
    },
    {
        lable:'Daylight',
        image:img_2
    },
    {
        lable:'Evening',
        image:img_3
    },
    {
        lable:'Night',
        image:img_5
    },
    {
        lable:'BedTime',
        image:img_4
    },
    
]

export const scroll2 = [
    {
        lable:'Before',
        image:img_1s2
    },
    {
        lable:'After',
        image:img_2s2
    },
    
]

export const scroll3 = [
    {
        lable:'Before',
        image:img_1s3
    },
    {
        lable:'After',
        image:img_2s3
    },
    
]

export const scroll4 = [
    {
        lable:'Before',
        image:img_1s4
    },
    {
        lable:'After',
        image:img_2s4
    },
    
]

export const scroll5 = [
    {
        lable:'Before',
        image:img_1s5
    },
    {
        lable:'After',
        image:img_2s5
    },
    
]