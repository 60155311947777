import React, { useRef } from "react";
import "./App.css";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./indexPage";
import AboutMe from "./AboutME";
import Layout from "./Layout"
import ProjectInfo from "./Pages/ProjectInfo";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Scrolls from "./ScrollsList";
import TitlebarBelowMasonryImageList from "./ScrollsMainPage";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="/AboutME" element={<AboutMe />} />
          <Route path="/ProjectInfo" element={<ProjectInfo />} />
          <Route path="/Scrolls" element={<TitlebarBelowMasonryImageList />} />
          <Route path="/ScrollsInfo" element={<Scrolls />} />
        </Route>
      </Routes>
    </BrowserRouter>
    // <Router>
    //   <Switch>
    //     <Route path="/" exact component={Index} />
    //     <Route path="/AboutME" exact component={AboutMe} />
    //     <Route path="/ProjectInfo" exact component={ProjectInfo} />
    //   </Switch>
    // </Router>
  );
}

export default App;
