import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

export default function TitlebarBelowMasonryImageList() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 483px)' })
    const navigate = useNavigate();
  function openInfo(item) {
    navigate("/ScrollsInfo",{state:{item}});
  }

  return (
    <Box sx={{ width: '100%', height: window.innerHeight, overflowY: 'none'}}>
      <ImageList variant="masonry" cols={isTabletOrMobile ? 1 :3} gap={8} style={{backgroundColor:'black'}}>
        {itemData.map((item) => (
          <ImageListItem key={item.img} onClick={() => openInfo(item)}>
            <img
              src={`${item.img}?w=240&fit=crop&auto=format`}
              srcSet={`${item.img}?w=240&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar position="below" title={item.title} style={{marginLeft : isTabletOrMobile && 10}}/>
            {isTabletOrMobile && <div style={{height:1, width: "auto", margin: 20,marginLeft:20, marginBottom:30, backgroundColor:"gray"}}></div>}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: require("./assets/Scrolls/scroll1/OFF.jpg"),
    title: 'BedRoom-Lighting mood setup',
    name:'scroll1',
    default:require("./assets/Scrolls/scroll1/OFF.jpg")
  },
  {
    img: require("./assets/Scrolls/scroll4/after.jpg"),
    title: 'Lighting',
    name:'scroll4',
    default:require("./assets/Scrolls/scroll4/before.jpg")
  },
  {
    img: require("./assets/Scrolls/scroll2/after.jpg"),
    title: 'Interior',
    name:'scroll2',
    default:require("./assets/Scrolls/scroll2/before.jpg")
  },
  {
    img: require("./assets/Scrolls/scroll5/after.jpg"),
    title: 'Interior',
    name:'scroll5',
    default:require("./assets/Scrolls/scroll5/before.jpg")
  },
  {
    img: require("./assets/Scrolls/scroll3/after.jpg"),
    title: 'Architect',
    name:'scroll3',
    default:require("./assets/Scrolls/scroll3/before.jpg")
  },
  
  
  
];