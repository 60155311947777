import React, { useRef } from "react";
import "./App.css";
import "./loadblock.scss";
import "./button.scss";
import "./cursor.scss";
import "./assets/fonts/Zodchiy-Bold 2.0.ttf";
import { useState, useEffect } from "react";
import "./gallery2.css";
import person from "./assets/personCropnew.png";
import personMobile from "./assets/personCropnewMobile.png";
import contact1 from "./assets/contact1.png";
import contact2 from "./assets/contact2.png";
import mail from "./assets/mail.png";
import yt from "./assets/yt.png";
import fb from "./assets/fb1.png";
import ig from "./assets/ig.png";
import explore from "./assets/explore.png";
import click1 from "./assets/click4.mp3";
import Image from "react-bootstrap/Image";
import Switch from "react-switch";
import "./textdisplay.css";
import Projects from "./Projects";
import lightMode from "./assets/lightgrey.png";
import dark from "./assets/dark.png";
import { Opacity } from "@mui/icons-material";
import { useMediaQuery } from 'react-responsive'

function Index() {
  const [offset, setOffset] = useState(0);
  const [getSwitch, setSwitch] = useState(true);
  const myDivRef = useRef(null);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 483px)' })
  useEffect(() => {
    // window.onbeforeunload = localStorage.clear();
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  let audio = new Audio(click1);

  function handleScroll() {
    const header = document.getElementById("header");
    const secondPage = document.getElementById("lineUp")
    if (header !== undefined && header != null) {
      if (offset > 1200) {
        header.className = "App-header-scroll";
      }

      if (offset < 950) {
        header.className = "animation";
      }
    }
  }
  function handleClikc() {
    audio.play();
  }

  function handleChange() {
    audio.play();
    setSwitch(!getSwitch);
  }
  return (
    <div className={getSwitch ? "App" : "AppGrey"}>
      <div
        style={{
          position: "absolute",
          right: "2%",
          top: "1%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <button
          className={getSwitch ? "btn btn-5" : "btn btn-5"}
          style={{
            color: getSwitch ? "white" : "white",
            textDecoration: "none",
            width: 30,
            height: 30,
            borderRadius: 30,
            padding: 0,
            marginRight: 10,
          }}
          onClick={() => handleClikc()}
        >
          <a
            href={
              "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sendmailtoyathish@gmail.com"
            }
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={mail}
              style={{
                height: 30,
                width: 30,
                zIndex: 99,
                padding: 0,
                alignSelf: "center",
                margin: 0,
              }}
              alt=""
            />
          </a>
        </button>
        <button
          className={getSwitch ? "btn btn-5" : "btn btn-5"}
          style={{
            color: getSwitch ? "white" : "white",
            textDecoration: "none",
            width: 30,
            height: 30,
            borderRadius: 30,
            padding: 0,
            marginRight: 10,
          }}
          onClick={() => handleClikc()}
        >
          <a  rel="noreferrer">
            <Image
              src={yt}
              style={{
                height: 30,
                width: 30,
                zIndex: 99,
                padding: 0,
                alignSelf: "center",
                margin: 0,
              }}
              alt=""
            />
          </a>
        </button>
        <button
          className={getSwitch ? "btn btn-5" : "btn btn-5"}
          style={{
            color: getSwitch ? "white" : "white",
            textDecoration: "none",
            marginRight: 10,
            width: 30,
            height: 30,
            borderRadius: 30,
            padding: 0,
          }}
          onClick={() => handleClikc()}
        >
          <a
            href={"https://instagram.com/yathish.why?igshid=YmMyMTA2M2Y="}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={ig}
              style={{
                height: 30,
                width: 30,
                zIndex: 99,
                padding: 0,
                alignSelf: "center",
                margin: 0,
              }}
              alt=""
            />
          </a>
        </button>
        <button
          className={getSwitch ? "btn btn-5" : "btn btn-5"}
          style={{
            color: getSwitch ? "white" : "white",
            textDecoration: "none",
            width: 30,
            height: 30,
            borderRadius: 30,
            padding: 0,
            marginRight: 10,
          }}
          onClick={() => handleClikc()}
        >
          <a
            href={"https://www.linkedin.com/in/yathish-yogish"}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={contact2}
              style={{
                height: 30,
                width: 30,
                zIndex: 99,
                padding: 0,
                alignSelf: "center",
                margin: 0,
              }}
              alt=""
            />
          </a>
        </button>
        <button
          className={getSwitch ? "btn btn-5" : "btn btn-5"}
          style={{
            color: getSwitch ? "white" : "white",
            textDecoration: "none",
            width: 30,
            height: 30,
            borderRadius: 30,
            padding: 0,
          }}
          onClick={() => handleClikc()}
        >
          <a  rel="noreferrer">
            <Image
              src={explore}
              style={{
                height: 30,
                width: 30,
                zIndex: 99,
                padding: 0,
                alignSelf: "center",
                margin: 0,
              }}
              alt=""
            />
          </a>
        </button>
      </div>
      <div
        className={
          getSwitch
            ? "App-header img-fluid shadow-4"
            : "App-header-grey img-fluid shadow-4"
        }
      >
        <div style={{ position: "relative", marginTop: 200 }}>
          <div className="loading-text">
            <span className="loading-text-words">Y</span>
            <span className="loading-text-words">A</span>
            <span className="loading-text-words">T</span>
            <span className="loading-text-words">H</span>
            <span className="loading-text-words">I</span>
            <span className="loading-text-words">S</span>
            <span className="loading-text-words">H</span>
          </div>
        </div>
        <promote style={{ fontSize: isTabletOrMobile ? 15 : 30, display: "flex", flexWrap: "wrap" }}>
          "I followed my heart, and it led me to the Northern Lights."
        </promote>
        <div>
          <div style={{ position: "relative", marginTop: 200 }}></div>
        </div>

        <div
          style={{
            marginTop: 30,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Switch
            width={60}
            height={30}
            handleDiameter={30}
            checked={getSwitch}
            onChange={handleChange}
            offHandleColor="#77b300"
            checkedIcon={false}
            uncheckedIcon={false}
            checkedHandleIcon={
              <img
                src={lightMode}
                style={{
                  height: 20,
                  width: 20,
                  marginLeft: 5,
                }}
              />
            }
            uncheckedHandleIcon={
              <img
                src={dark}
                style={{
                  height: 20,
                  width: 20,
                  marginLeft: 5,
                }}
              />
            }
          />
        </div>
      </div>
      {/* <SecondPage/> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: isTabletOrMobile ? "flex-end" : "center",
          height: "auto",
          width: "100%",
          background: getSwitch ? "#06461b" : "#000000",
          flexDirection: isTabletOrMobile ? "column" : "row"
        }}
      >
        {isTabletOrMobile ? <><div
          style={{
            display: "block",
            backgroundSize: "cover",
            width: "auto",
            zIndex: 1,
          }}
        >
          <Image src={personMobile} style={{ height: "auto", width:"auto", resize:'contain', alignSelf: 'flex-end', width: 432 }} alt="" />
        </div><div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: isTabletOrMobile ? 5 : 180,
          }}
        >
            <p
              style={{
                fontSize: isTabletOrMobile ? 90 : 120,
                fontFamily: "Bold",
                color: getSwitch ? "#ffffff" : "#ffffff",
                textAlign: "center",
                margin: "auto",
                marginRight: 20,
                opacity: 0
              }}
              className={offset > 800 ? "lineUp" : "linedown"}
              id="lineUp"
            >
              YATHISH YOGISH
            </p>
            <p
              style={{
                fontFamily: "futur",
                fontSize: isTabletOrMobile ? 18 : 20,
                color: getSwitch ? "#ffffff" : "#ffffff",
                textAlign: "center",
                margin: 0,
              }}
            >
              Architect, Lighting Designer, <br /> IALD Jr. Associate, Astrophile.
            </p>
            <div
              style={{ display: "flex", alignItems: "flex-start", marginTop: 50 }}
            >
              <button
                className={getSwitch ? "btn btn-5" : "btn btn-5"}
                onClick={() => {
                  myDivRef.current.scrollIntoView({ behavior: "smooth" });
                  handleClikc();
                }}
                style={{
                  color: getSwitch ? "white" : "white",
                  textDecoration: "none",
                  marginRight: 20,
                }}
              >
                PROJECTS
              </button>

              <a
                onClick={() => handleClikc()}
                className={getSwitch ? "btn btn-5" : "btn btn-5"}
                href="AboutME"
                style={{
                  color: getSwitch ? "white" : "white",
                  textDecoration: "none",
                }}
              >
                ABOUT ME
              </a>
              <a
                onClick={() => handleClikc()}
                className={getSwitch ? "btn btn-5" : "btn btn-5"}
                href="/Scrolls"
                style={{
                  color: getSwitch ? "white" : "white",
                  textDecoration: "none",
                  marginLeft: 20,
                }}
              >
                SCROLLS
              </a>
            </div>
          </div></> : <><div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              marginLeft: isTabletOrMobile ? 20 : 180,
            }}
          >
            <p
              style={{
                fontSize: 120,
                fontFamily: "Bold",
                color: getSwitch ? "#ffffff" : "#ffffff",
                textAlign: "start",
                margin: "auto",
                opacity: 0
              }}
              className={offset > 700 ? "lineUp" : "linedown"}
              id="lineUp"
            >
              YATHISH YOGISH
            </p>
            <p
              style={{
                fontFamily: "futur",
                fontSize: 30,
                color: getSwitch ? "#ffffff" : "#ffffff",
                textAlign: "start",
                margin: 0,
              }}
            >
              Architect, Lighting Designer, <br /> IALD Jr. Associate, Astrophile.
            </p>
            <div
              style={{ display: "flex", alignItems: "flex-start", marginTop: 50, width: 'auto' }}
            >
              <button
                className={getSwitch ? "btn btn-5" : "btn btn-5"}
                onClick={() => {
                  myDivRef.current.scrollIntoView({ behavior: "smooth" });
                  handleClikc();
                }}
                style={{
                  color: getSwitch ? "white" : "white",
                  textDecoration: "none",
                  marginRight: isTabletOrMobile ? 10 : 20,
                }}
              >
                PROJECTS
              </button>

              <a
                onClick={() => handleClikc()}
                className={getSwitch ? "btn btn-5" : "btn btn-5"}
                href="AboutME"
                style={{
                  color: getSwitch ? "white" : "white",
                  textDecoration: "none",
                }}
              >
                ABOUT ME
              </a>
              <a
                onClick={() => handleClikc()}
                className={getSwitch ? "btn btn-5" : "btn btn-5"}
                href="/Scrolls"
                style={{
                  color: getSwitch ? "white" : "white",
                  textDecoration: "none",
                  marginLeft: isTabletOrMobile ? 10 : 20,
                }}
              >
                SCROLLS
              </a>
            </div>
          </div><div
            style={{
              display: "block",
              backgroundSize: "cover",
              // backgroundImage: `url(${person})`,
              width: 735,
              zIndex: 1,
            }}
          >
            <Image src={person} style={{ height: "auto" }} alt="" />
          </div></>}


      </div>
      <div
        className="animation"
        id="header"
        onScroll={handleScroll()}
        style={{
          backgroundColor: "#000000",
          filter: getSwitch
            ? "grayscale(0%) brightness(100%)"
            : "grayscale(100%) brightness(100%)",
        }}
      >
        <div ref={myDivRef} />
        <Projects getSwitch={getSwitch} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              fontFamily: "Bold",
              fontSize: 80,
              marginTop: 80,
              padding: 0,
            }}
          >
            Let's Talk
          </p>
          <div
            style={{
              height: 0.5,
              width: 90,
              backgroundColor: "grey",
              marginBottom: 20,
              marginTop: 20,
            }}
          />
          <p style={{ fontFamily: "futur", fontSize: 14, margin: 20 }}>
            Bengaluru, Karnataka, India
          </p>
          <a
            href={
              "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sendmailtoyathish@gmail.com"
            }
            style={{
              textDecoration: "none",
              fontFamily: "futur",
              marginBottom: 20,
            }}
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "white", fontSize: 12 }}>
              Contact : sendmailtoyathish@gmail.com
            </p>
          </a>
          {/* <div className="loaderblock loader-17">
            <div className="css-square square1"></div>
            <div className="css-square square2"></div>
            <div className="css-square square3"></div>
            <div className="css-square square4"></div>
            <div className="css-square square5"></div>
            <div className="css-square square6"></div>
            <div className="css-square square7"></div>
            <div className="css-square square8"></div>
            <div className="css-square square9"></div>
            <div className="css-square square10"></div>
            <div className="css-square square11"></div>
          </div> */}
          <div style={{ width: "100%", margin: 20 }}>
            {/* <button
              className={getSwitch ? "btn btn-5" : "btn btn-5"}
              style={{
                color: getSwitch ? "white" : "white",
                textDecoration: "none",
                width: 30,
                height: 30,
                borderRadius: 30,
                padding: 0,
                marginRight: 30,
              }}
              onClick={() => handleClikc()}
            >
              {/* <a  rel="noreferrer">
                <Image
                  src={fb}
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 99,
                    padding: 0,
                    alignSelf: "center",
                    margin: 0,
                  }}
                  alt=""
                />
              </a> 
            </button> */}
            <button
              className={getSwitch ? "btn btn-5" : "btn btn-5"}
              style={{
                color: getSwitch ? "white" : "white",
                textDecoration: "none",
                width: 30,
                height: 30,
                borderRadius: 30,
                padding: 0,
                marginRight: 30,
              }}
              onClick={() => handleClikc()}
            >
              <a
                href={
                  "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sendmailtoyathish@gmail.com"
                }
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={mail}
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 99,
                    padding: 0,
                    alignSelf: "center",
                    margin: 0,
                  }}
                  alt=""
                />
              </a>
            </button>
            <button
              className={getSwitch ? "btn btn-5" : "btn btn-5"}
              style={{
                color: getSwitch ? "white" : "white",
                textDecoration: "none",
                width: 30,
                height: 30,
                borderRadius: 30,
                padding: 0,
                marginRight: 30,
              }}
              onClick={() => handleClikc()}
            >
              <a  rel="noreferrer">
                <Image
                  src={yt}
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 99,
                    padding: 0,
                    alignSelf: "center",
                    margin: 0,
                  }}
                  alt=""
                />
              </a>
            </button>
            <button
              className={getSwitch ? "btn btn-5" : "btn btn-5"}
              style={{
                color: getSwitch ? "white" : "white",
                textDecoration: "none",
                marginRight: 30,
                width: 30,
                height: 30,
                borderRadius: 30,
                padding: 0,
              }}
              onClick={() => handleClikc()}
            >
              <a
                href={"https://instagram.com/yathish.why?igshid=YmMyMTA2M2Y="}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={ig}
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 99,
                    padding: 0,
                    alignSelf: "center",
                    margin: 0,
                  }}
                  alt=""
                />
              </a>
            </button>
            <button
              className={getSwitch ? "btn btn-5" : "btn btn-5"}
              style={{
                color: getSwitch ? "white" : "white",
                textDecoration: "none",
                width: 30,
                height: 30,
                borderRadius: 30,
                padding: 0,
                marginRight: 30,
              }}
              onClick={() => handleClikc()}
            >
              <a
                href={"https://www.linkedin.com/in/yathish-yogish"}
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={contact2}
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 99,
                    padding: 0,
                    alignSelf: "center",
                    margin: 0,
                  }}
                  alt=""
                />
              </a>
            </button>
            <button
              className={getSwitch ? "btn btn-5" : "btn btn-5"}
              style={{
                color: getSwitch ? "white" : "white",
                textDecoration: "none",
                width: 30,
                height: 30,
                borderRadius: 30,
                padding: 0,
              }}
              onClick={() => handleClikc()}
            >
              <a  rel="noreferrer">
                <Image
                  src={explore}
                  style={{
                    height: 30,
                    width: 30,
                    zIndex: 99,
                    padding: 0,
                    alignSelf: "center",
                    margin: 0,
                  }}
                  alt=""
                />
              </a>
            </button>
          </div>
          <div style={{ fontFamily: 'futur', fontSize: 14 }}>
            {/* <strong><span style={{ fontFamily: 'futur', fontSize: 14 }}>Yathish Yogish</span></strong>.  */}
            {/* <div> */}
            &copy; Copyright All Rights Reserved | Designed by <a href="https://yathish.in" style={{textDecoration:"none"}}>Yathish Yogish</a>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
