import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Lightbox from "react-image-lightbox";
import { useLocation } from "react-router-dom";
import "react-image-lightbox/style.css";
import { Proj1, Proj2, Proj3, Proj4, Proj5, Proj6, Proj7, Proj8, Proj10, Proj11, Proj12, Proj13, Proj14, Proj15, Proj0, Proj16, Proj17, Proj18, Proj19 } from "./ProjectDatalist";
import insta from "../assets/expand.png";
import { useMediaQuery } from 'react-responsive'

function ProjectInfo() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 483px)' })
  const location = useLocation();
  // console.log("data",getProjectDetails);
  const [getImageList, setImageList] = useState([]);
  const [getProjectDetails, setProjectDetails] = useState({})
  const Projects = {
    Proj1: {
      data: Proj1, img: require("../assets/Proj1/1.jpg"),
      title: "Coffee Gang Film Studio",
      rows: 2,
      name: "Proj1",
      clientName: "Coffee Gang Film Studio",
      description: "Our clients, who were start-up developers and film producers with a passion for nature, approached us with a 140 Sqm east-facing location, a 9m wide road ahead, and an old tree in front. They wanted to create a space that was 60% garden and 40% built environment, in line with their nature-friendly ethos",
      description1: "Our challenge as Neoteric fellows was to design a minimalist structure with an aesthetic approach that aligned with their budget while still sparking a modern building philosophy. Our goal was to create a space that would draw in customers and create a friendly and welcoming atmosphere"
    },
    Proj2: {
      data: Proj2, img: require("../assets/Proj2/00.jpg"),
      title: "Yoga House - Portugal",
      rows: 3,
      name: "Proj2",
      description: "The Yoga House on a Cliff Competition was organized in tandem with the Vale de Moses landowners, seeking designs for a new hillside building overlooking the valley that would increase capacity for the number of yoga participants the retreat can accommodate. Given its natural setting and meditative purpose, the future yoga house is meant to inspire guests while connecting them with the surrounding mountains and forests. The competition brief requested that, in addition to a central yoga space, the house includes: storage space for mats and other yoga equipment; showers and toilets; a small kitchen area for teas and coffee. All grey water produced from bathrooms is to be used to irrigate the surrounding gardens. The new building must also produce its own energy."
    },
    Proj3: {
      data: Proj3, img: require("../assets/Proj3/6.jpg"),
      title: "Transit Hub",
      rows: 3,
      name: "Proj3",
      clientName: "Studio 5",
      description: "Transit Hub is a transfer point where people are transported by subway and rail from one place to another, the aim of the project is to provide adequate cultural events around M.G Road and provide the best transport facilities for the public, and it provides an simple connection between city and airport via monorail train.",
      description1: "Concept",
      description2: "“The morphology of the dragonfly wing is an optimal natural construction via a complex patterning process, developed through an evolution as a response to force flows and material organization. The wing achieves efficient structural performance through a nonlinear variation of the pattern, corrugations, and varied material properties throughout the structure.”"
    },
    Proj4: {
      data: Proj4, img: require("../assets/Proj4/10.jpg"),
      title: "International cruise terminal",
      rows: 2,
      cols: 2,
      name: "Proj4",
      clientName: "Studio 8",
      description: "A Cruise Ship or cruise linear is a passenger ship used for pleasure voyages, where the voyages itself and the ship’s amenities are a part of the experience, as well as the different along the way.",
      description1: "Location: Mangalore, Karnataka, India. \n Area: 60Acre",
      description2: "Aim of the project is to provide a space for a “CRUISE TERMINAL” which is accessible, innovative and inclusive of a”CULTURAL HUB”. Department of Tourism (DoT) Government of Karnataka (GoK) is determined to boost cruise tourism industry in the state.",
      description3: "Concept:",
      description4: "The patterns that occur in the sea, the seashells mark such variety that is reflected in their forms, crevices, lines drawn into infinity, creases, curves, and holes that create shadows and mystery within them. Seashells can be identified as a unique collection of nature that offers limitless pathways to find natural orders in them."
    },
    Proj5: {
      data: Proj5, img: require("../assets/lightgif.gif"),
      title: "Eye Hospital - Facade lighting",
      name: "Proj5",
      description: "the conceptualization of the Qatar hospital project. Since the facade faced a major intersection in the middle of a city, the client wanted to think of a novel way to light it so that it wouldn’t look like a typical hospital.",
      description1: "Lighitng design for the building facade The building facade’s lighting design was inspired by the idea of waves transitioning from a consolidated form to the actual flexible tide nature.Pursuing light as a sculpture when emitted on the flexible facade, which brings back the tendency of nature wave to the consolidated form;additionally, colours are used for healing many mental disorders; in a similar manner hospital facade lighting can be a healing crystal that emits positive vibes."
    },
    Proj6: {
      data: Proj6, img: require("../assets/Proj6/10.jpg"),
      title: "Airbase - Operation centre",
      rows: 3,
      name: "Proj6",
      clientName: "",
      description: "The lighting design in an airbase operations center is critical in ensuring optimal visibility for the personnel working in the space. In this center, the lighting design includes the use of dimmable downlighting and indirect covelights. The dimmable downlighting allows for the adjustment of lighting levels based on the needs of the personnel, while the indirect covelights provide a more evenly distributed light source. The combination of these lighting techniques results in a well-lit space that is conducive to effective communication, decision-making, and overall operational efficiency"
    },
    Proj7: { data: Proj7, title: "Title for Proj7" },
    Proj8: {
      data: Proj8, img: require("../assets/Proj8/3.jpg"),
      title: "Somashekar Residence",
      name: "Proj8",
    },
    Proj10: {
      data: Proj10, img: require("../assets/Proj10/0.jpg"),
      title: "Light machine",
      name: "Proj10",
      // description: projectInfo
      description: "In this project, given a specific room in the KTH campus and two different light sources in order to create a lighting machine. The lighting machine has to consider the room conditions and a concept must be developed prior to making the final output. The aim of the task is to work with the properties of light and materials, showing a good lighting distribution/effect more than a beautiful product."
    },
    Proj11: {
      data: Proj11, img: require("../assets/Proj11/0.jpg"),
      title: "Form Follows flow",
      name: "Proj11",
      description:
        "The site is located in KTH campus which as four pleasant trees and as designed for people to sit and have some good time. The place was serving better in the morning but night it turned up to be a dark space with no focal point or directly lighting. Information received from our survey and the observations gathered from our analysis, we understood that the constant flow of people through the space almost define sits character, however the same people using the space do not have a lasting impression of the space itself. We looked to change this and create a memorable moment in one’s journey through our site, and to do this we decided to use the one thing which defines this space-movement.",
    },
    Proj12: {
      data: Proj12, img: require("../assets/Proj12/17.jpg"),
      title: "Energy/Scrulptur",
      name: "Proj12",
      description: "The sculptor is primarily concerned with making connections between the forms found in nature and recognizing that its structures are uniquely designed. In this manner, how the neurons in our body are designed and what designs a neuron are revealed. The sculptor’s work emphasizes the aesthetics of nature’s structures, which are transformed into an energy installation (15 ft. in length) that depicts the unknown within the forms of every cell in every living organism.",
      description1: "Sculptor had an idea of hanging the entire creation with fishing wires to human eye level. Sculptor’s idea of lighting his creation was stated in one definition via following conversation.",
      description2: "Sculptor: “Can we make the audience feel the neurons?” “Present lighting condition is too bad for my sculpture”",
      description3: "Lighting Designer: Just feel them? Shall we make them walk on it!",
      description4: "The illumination scenario is a simple illustration of the concept of light and shadow. The track lights installed with a distance of 1 foot (15no) between them, emitting shadow beam angles of 12 degrees and 24 degrees alternatively.In this scenario, we will explore how neurons react to different types of light exposure by simulating the retina as an array of neurons that respond differently depending on their position in relation to incoming photons (light particles).",
      description5: "Enhancing Visual Depth: We decided to wrap the projectors in yellow filament, which created a warm glow and added visual depth. We also used white butter paper to create a sobering effect."
    },
    Proj13: {
      data: Proj13, img: require("../assets/Proj13/1.jpg"),
      title: "Tatastha/Scrulptur",
      name: "Proj13",
      rows: 2,
      description: "This endeavour was a collaboration with an Indian sculptor from Bangalore’s Chithra Kala Parishad. The sculptor has developed a one-of-a-kind piece made solely of incense sticks. The sculptor’s childhood recollections inspire the sculpture’s form and pattern. In the same way that scent can evoke specific memories, the sculptor attempts to capture the essence of his formative years in his work.Sculptor’s idea of lighting his creation was stated in one definition via following conversation.",
      description1: "Sculptor:  “I want my audience to experience the vividness of my formative memories.”   “Is it possible to go back in time”? ",
      description2: "Lighting Designer: “Of course time travelling is a proven theory now it’s just an engineering problem. “The Illumination Scenario: To create a voyage back in time, the installation was hung by fishing wire in a human-sided black box, which creates the ideal atmosphere for the sculpture to resonate with viewers.",
      description3: "Tuneable and Dimmable projectors of 80-degree beam angle with honey comb mesh to avoid glare were mounted on the two top ends of the black box, allowing shadows to act as a power of art to evoke emotions and memories. Incense purifies air, which taps into one of humans’ senses to go back in time with calm music, creating a drama within the enclosed environment.",
      description4: "The power of shapes and memories, The shape of a box, for example, can be used to evoke the feeling of being in a room. The shadow of this shape will bring up memories from childhood and create an intimate work of art that evokes emotions and memories.",
    },
    Proj14: {
      data: Proj14, img: require("../assets/Proj14/12.jpg"),
      title: "Srikanth Residence",
      name: "Proj14"
    },
    Proj15: {
      data: Proj15, img: require("../assets/Proj15/1.jpg"),
      title: "Vishwakarma temple",
      rows: 2,
      cols: 2,
      name: "Proj15",
      description: "The lighting design in a temple plays a crucial role in creating the right atmosphere for worshippers. In this temple, a combination of ambient uplights and strip lights were used to enhance the top decorated space and gopuram. The objective was to highlight the intricate architectural elements and create a peaceful and calming environment for visitors.",
      description1: "Additionally, decorative lanterns were placed on the compound walls to add to the aesthetic appeal of the temple. The strategic placement of the lights and the use of decorative elements resulted in a successful lighting design that not only enhanced the overall space but also contributed to the calmness of the visitors.",
      description2: "one definition via following conversation.",
      description3: "Temple trust:  “We dont have lot of budget for lighting”,“We want maintenance free lights”,",
      description4: "Lighting Designer: “Budget can be reduced by using local suppliers”, dont worry!. “Lighting can be mainteance free if its installed in a right way”."
    },
    Proj0: {
      data: Proj0, img: require("../assets/Proj0/1.jpg"),
      title: "Lighting & wellness",
      name: "Proj0",
      rows: 2,
      cols: 2,
    },
    Proj16: {
      data: Proj16, img: require("../assets/Proj16/2.jpg"),
      title: "Coffee Gang Film Studio.",
      name: "Proj16",
      clientName: "Coffee Gang Film Studio",
      description: "Our clients, who were start-up developers and film producers with a passion for nature, approached us with a 140 Sqm east-facing location, a 9m wide road ahead, and an old tree in front. They wanted to create a space that was 60% garden and 40% built environment, in line with their nature-friendly ethos",
      description1: "Our challenge as Neoteric fellows was to design a minimalist structure with an aesthetic approach that aligned with their budget while still sparking a modern building philosophy. Our goal was to create a space that would draw in customers and create a friendly and welcoming atmosphere"
    },
    Proj17: {
      data: Proj17, img: require("../assets/Proj17/cover.jpg"),
      title: "Light and indoor",
      name: "Proj17",
      rows: 2,
      description: "In Alsike’s ever-transforming new neighbourhood, our design concept is based on a kind of urban archaeology that references Stockholm city’s past, present, and future. The hotel is conceived as a non-linear timeline, and each floor reveals a different layer in the neighbourhood’s narrative—as well as projecting its future creating a sense of discovery for guests and transforming its stories from day to night."
    },
    Proj18: {
      data: Proj18, img: require("../assets/Proj18/1.jpg"),
      title: "Thomas Residence",
      name: "Proj18",
      rows: 2
    },
    Proj19: {
      data: Proj19, img: require("../assets/Proj18/2.jpg"),
      title: "Keshav Residence",
      name: "Proj19",
    }
  };

  useEffect(() => {
    // Parse the URL search parameters
    const params = new URLSearchParams(location.search);
    const projectName = params.get('name');

    if (projectName) {
      // Decoding the project name to handle URL encoding
      const decodedProjectName = decodeURIComponent(projectName);

      // Look for the project in the Projects object
      for (const key in Projects) {
        const project = Projects[key];
        if (project.title === decodedProjectName) {
          setImageList(project.data); // Use project.data to set the image list
          setProjectDetails(project)
          break;
        }
      }
    }
  }, [location.search]);

  const [index, setIndex] = useState(-1);
  const [getActive, setActive] = useState(-1);

  const currentImage = getImageList[index];
  const nextIndex = (index + 1) % getImageList.length;
  const nextImage = getImageList[nextIndex] || currentImage;
  const prevIndex = (index + getImageList.length - 1) % getImageList.length;
  const prevImage = getImageList[prevIndex] || currentImage;

  const handleClick = (event) => {
    setIndex(Number(event.target.id));
    setActive(event);
    // console.log("event", event.target, images[event.target.id]);
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "black",
        flexDirection: isTabletOrMobile ? "column" : "row",
        height: window.innerHeight,
      }}
    >
      <div
        style={{
          display: "flex",
          width: isTabletOrMobile ? "100%" : "50%",
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Carousel
          indicators={false}
          nextLabel=""
          prevLabel=""
          interval={3000}
          pause={"hover" | false}
        //   onSelect={(e) => handleClick(e)}
        >
          {getImageList.map((element) => {
            return (
              <Carousel.Item>
                <img
                  style={{
                    height: isTabletOrMobile ? window.innerHeight - 400 : window.innerHeight,
                    objectFit: "cover",
                    objectPosition: "center",
                    width: 960,
                  }}
                  {...srcset(element.src, 220, 2, 2)}
                  alt="First slide"
                />
                <div
                  style={{
                    height: 40,
                    width: 40,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 10,
                    background: "gray",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 8,
                    margin: 4
                  }}
                  onClick={(e) => handleClick(e)}
                >
                  <img src={insta} />
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div
        style={{
          display: "flex",
          backgroundColor: "gray",
          flexDirection: "column",
          width: isTabletOrMobile ? "100%" : "50%",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#06461b",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontFamily: 'futur'
          }}
        >
          {/* <p style={{ color: "white" }}>{getProjectDetails.clientName}</p> */}
          <p style={{ color: "white" }}>{getProjectDetails.title}</p>
        </div>
        <div
          style={{
            display: "flex",
            backgroundColor: "black",
            height: "50%",
            justifyContent: "start",
            alignItems: "center",
            overflow: 'auto',
            flexDirection: 'column'
          }}
        >
          <p style={{ whiteSpace: 'pre', color: "white", marginLeft: 5, marginTop: 5, textAlign: 'left', fontFamily: 'futur', fontSize: '22' }}>ABSTRACT</p>
          <p style={{ color: "white", marginLeft: isTabletOrMobile ? 70 : 120, marginRight: isTabletOrMobile ? 70 : 120, marginTop: 5, textAlign: 'left', fontFamily: 'futur' }}>
            {getProjectDetails.description}
          </p>
          <p style={{ color: "white", marginLeft: isTabletOrMobile ? 70 : 120, marginRight: isTabletOrMobile ? 70 : 120, marginTop: 5, textAlign: 'left', fontFamily: 'futur' }}>
            {getProjectDetails.description1}
          </p>
          <p style={{ color: "white", marginLeft: isTabletOrMobile ? 70 : 120, marginRight: isTabletOrMobile ? 70 : 120, marginTop: 5, textAlign: 'left', fontFamily: 'futur' }}>
            {getProjectDetails.description2}
          </p>
          <p style={{ color: "white", marginLeft: isTabletOrMobile ? 70 : 120, marginRight: isTabletOrMobile ? 70 : 120, marginTop: 5, textAlign: 'left', fontFamily: 'futur' }}>
            {getProjectDetails.description3}
          </p>
          <p style={{ color: "white", marginLeft: isTabletOrMobile ? 70 : 120, marginRight: isTabletOrMobile ? 70 : 120, marginTop: 5, textAlign: 'left', fontFamily: 'futur' }}>
            {getProjectDetails.description4}
          </p>
          <p style={{ color: "white", marginLeft: isTabletOrMobile ? 70 : 120, marginRight: isTabletOrMobile ? 70 : 120, marginTop: 5, textAlign: 'left', fontFamily: 'futur' }}>
            {getProjectDetails.description5}
          </p>
        </div>
      </div>
      <div>
        {!!currentImage && (
          /* @ts-ignore */
          <Lightbox
            mainSrc={currentImage.src}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectInfo;
