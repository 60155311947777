import { Image } from "react-grid-gallery";
import img_1 from "../assets/Proj1/1.jpg";
import img_2 from "../assets/Proj1/2.jpg";
import img_3 from "../assets/Proj1/8.jpg";
import img_4 from "../assets/Proj1/4.jpg";
import img_5 from "../assets/Proj1/5.jpg";
import img_6 from "../assets/Proj1/6.jpg";
import img_7 from "../assets/Proj1/7.jpg";
import img_8 from "../assets/Proj1/8.jpg";
import img_9 from "../assets/Proj1/9.jpg";
import img_10 from "../assets/Proj1/10.jpg";
import img_11 from "../assets/Proj1/11.jpg";
import img_12 from "../assets/Proj1/12.jpg";
// import img_13 from "../assets/Proj1/13.jpg";
// import img_14 from "../assets/Proj1/14.jpg";
// import img_15 from "../assets/Proj1/15.jpg";
// import img_16 from "../assets/Proj1/16.jpg";
// import img_17 from "../assets/Proj1/17.jpg";
// import img_18 from "../assets/Proj1/18.jpg";

import img_0p2 from "../assets/Proj2/00.jpg";
import img_1p2 from "../assets/Proj2/01.jpg";
import img_2p2 from "../assets/Proj2/02.jpg";
import img_3p2 from "../assets/Proj2/03.jpg";
import img_4p2 from "../assets/Proj2/04.jpg";

import img_1p3 from "../assets/Proj3/1.jpg";
import img_2p3 from "../assets/Proj3/2.jpg";
import img_3p3 from "../assets/Proj3/3.jpg";
import img_4p3 from "../assets/Proj3/4.jpg";
import img_5p3 from "../assets/Proj3/5.jpg";
import img_6p3 from "../assets/Proj3/6.jpg";

import img_1p4 from "../assets/Proj4/1.jpg";
import img_2p4 from "../assets/Proj4/2.jpg";
import img_3p4 from "../assets/Proj4/3.jpg";
import img_4p4 from "../assets/Proj4/4.jpg";
import img_5p4 from "../assets/Proj4/5.jpg";
import img_6p4 from "../assets/Proj4/6.jpg";
import img_7p4 from "../assets/Proj4/7.jpg";
import img_8p4 from "../assets/Proj4/8.jpg";
import img_9p4 from "../assets/Proj4/9.jpg";
import img_10p4 from "../assets/Proj4/10.jpg";
import img_11p4 from "../assets/Proj4/11.jpg";
import img_12p4 from "../assets/Proj4/12.jpg";

import img_4p5 from "../assets/Proj5/lightgif.gif";
import img_1p5 from "../assets/Proj5/1.jpg";
import img_2p5 from "../assets/Proj5/2.jpg";
import img_3p5 from "../assets/Proj5/3.jpg";

import img_1p6 from "../assets/Proj6/1.jpg";
import img_2p6 from "../assets/Proj6/2.jpg";
import img_3p6 from "../assets/Proj6/3.jpg";
import img_4p6 from "../assets/Proj6/4.jpg";
import img_5p6 from "../assets/Proj6/5.jpg";
import img_6p6 from "../assets/Proj6/6.jpg";
import img_7p6 from "../assets/Proj6/7.jpg";
import img_8p6 from "../assets/Proj6/8.jpg";
import img_9p6 from "../assets/Proj6/9.jpg";
import img_10p6 from "../assets/Proj6/10.jpg";
import img_11p6 from "../assets/Proj6/11.jpg";
import img_12p6 from "../assets/Proj6/12.jpg";

import img_1p7 from "../assets/Proj7/1.jpg";
import img_2p7 from "../assets/Proj7/2.jpg";
import img_3p7 from "../assets/Proj7/3.jpg";
import img_4p7 from "../assets/Proj7/4.jpg";
import img_5p7 from "../assets/Proj7/5.jpg";
import img_6p7 from "../assets/Proj7/6.jpg";
import img_7p7 from "../assets/Proj7/7.jpg";
import img_8p7 from "../assets/Proj7/8.jpg";
import img_9p7 from "../assets/Proj7/9.jpg";
import img_10p7 from "../assets/Proj7/10.jpg";

import img_1p8 from "../assets/Proj8/1.jpg";
import img_2p8 from "../assets/Proj8/2.jpg";
import img_3p8 from "../assets/Proj8/3.jpg";
import img_4p8 from "../assets/Proj8/4.jpg";
import img_5p8 from "../assets/Proj8/5.jpg";
import img_6p8 from "../assets/Proj8/6.jpg";
import img_7p8 from "../assets/Proj8/7.jpg";
import img_8p8 from "../assets/Proj8/8.jpg";
import img_9p8 from "../assets/Proj8/9.jpg";
import img_10p8 from "../assets/Proj8/10.jpg";

import img_1p9 from "../assets/Proj9/1.jpg";
import img_2p9 from "../assets/Proj9/2.jpg";
import img_3p9 from "../assets/Proj9/3.jpg";
import img_4p9 from "../assets/Proj9/4.jpg";
import img_5p9 from "../assets/Proj9/5.jpg";
import img_6p9 from "../assets/Proj9/6.jpg";
import img_7p9 from "../assets/Proj9/7.jpg";
import img_8p9 from "../assets/Proj9/8.jpg";
import img_9p9 from "../assets/Proj9/9.jpg";
import img_10p9 from "../assets/Proj9/10.jpg";
import img_11p9 from "../assets/Proj9/11.jpg";
import img_12p9 from "../assets/Proj9/12.jpg";
import img_13p9 from "../assets/Proj9/13.jpg";
import img_14p9 from "../assets/Proj9/14.jpg";
import img_15p9 from "../assets/Proj9/15.jpg";
import img_16p9 from "../assets/Proj9/16.jpg";
import img_17p9 from "../assets/Proj9/17.jpg";
import img_18p9 from "../assets/Proj9/18.jpg";
import img_19p9 from "../assets/Proj9/19.jpg";
import img_20p9 from "../assets/Proj9/20.jpg";
import img_21p9 from "../assets/Proj9/21.jpg";

import img_0p10 from "../assets/Proj10/0.jpg";
import img_1p10 from "../assets/Proj10/1.jpg";
import img_2p10 from "../assets/Proj10/2.jpg";
import img_3p10 from "../assets/Proj10/3.jpg";
import img_4p10 from "../assets/Proj10/4.jpg";
import img_5p10 from "../assets/Proj10/5.jpg";
import img_6p10 from "../assets/Proj10/6.jpg";
import img_7p10 from "../assets/Proj10/7.jpg";
import img_8p10 from "../assets/Proj10/8.jpg";
import img_9p10 from "../assets/Proj10/9.jpg";
import img_10p10 from "../assets/Proj10/10.jpg";
import img_11p10 from "../assets/Proj10/11.jpg";

import img_0p11 from "../assets/Proj11/0.jpg";
import img_1p11 from "../assets/Proj11/1.jpg";
import img_2p11 from "../assets/Proj11/2.jpg";
import img_3p11 from "../assets/Proj11/3.jpg";
import img_4p11 from "../assets/Proj11/4.jpg";
import img_5p11 from "../assets/Proj11/5.jpg";
import img_6p11 from "../assets/Proj11/6.jpg";
import img_7p11 from "../assets/Proj11/7.jpg";
import img_8p11 from "../assets/Proj11/8.jpg";
import img_9p11 from "../assets/Proj11/9.jpg";
import img_10p11 from "../assets/Proj11/10.jpg";
import img_11p11 from "../assets/Proj11/11.jpg";
import img_12p11 from "../assets/Proj11/12.jpg";
import img_13p11 from "../assets/Proj11/13.jpg";
import img_14p11 from "../assets/Proj11/14.jpg";

import img_1p12 from "../assets/Proj12/1.jpg";
import img_2p12 from "../assets/Proj12/2.jpg";
import img_3p12 from "../assets/Proj12/3.jpg";
import img_4p12 from "../assets/Proj12/4.jpg";
import img_5p12 from "../assets/Proj12/5.jpg";
import img_6p12 from "../assets/Proj12/6.jpg";
import img_7p12 from "../assets/Proj12/7.jpg";
import img_8p12 from "../assets/Proj12/8.jpg";
import img_9p12 from "../assets/Proj12/9.jpg";
import img_10p12 from "../assets/Proj12/10.jpg";
import img_11p12 from "../assets/Proj12/11.jpg";
import img_12p12 from "../assets/Proj12/12.jpg";
import img_13p12 from "../assets/Proj12/13.jpg";
import img_14p12 from "../assets/Proj12/14.jpg";
import img_15p12 from "../assets/Proj12/15.jpg";
import img_16p12 from "../assets/Proj12/16.jpg";
import img_17p12 from "../assets/Proj12/17.jpg";
import img_18p12 from "../assets/Proj12/18.jpg";
import img_19p12 from "../assets/Proj12/19.jpg";
import img_20p12 from "../assets/Proj12/20.jpg";
import img_21p12 from "../assets/Proj12/21.jpg";
import img_22p12 from "../assets/Proj12/22.jpg";
import img_23p12 from "../assets/Proj12/23.jpg";
import img_24p12 from "../assets/Proj12/24.jpg";
import img_25p12 from "../assets/Proj12/25.jpg";
import img_26p12 from "../assets/Proj12/26.jpg";

import img_1p13 from "../assets/Proj13/1.jpg";
import img_2p13 from "../assets/Proj13/2.jpg";
import img_3p13 from "../assets/Proj13/3.jpg";
import img_4p13 from "../assets/Proj13/4.jpg";
import img_5p13 from "../assets/Proj13/5.jpg";
import img_6p13 from "../assets/Proj13/6.jpg";

import img_1p14 from "../assets/Proj14/1.jpg";
import img_2p14 from "../assets/Proj14/2.jpg";
import img_3p14 from "../assets/Proj14/3.jpg";
import img_4p14 from "../assets/Proj14/4.jpg";
import img_5p14 from "../assets/Proj14/5.jpg";
import img_6p14 from "../assets/Proj14/6.jpg";
import img_7p14 from "../assets/Proj14/7.jpg";
import img_8p14 from "../assets/Proj14/8.jpg";
import img_9p14 from "../assets/Proj14/9.jpg";
import img_10p14 from "../assets/Proj14/10.jpg";
import img_11p14 from "../assets/Proj14/11.jpg";
import img_12p14 from "../assets/Proj14/12.jpg";
import img_13p14 from "../assets/Proj14/13.jpg";
import img_14p14 from "../assets/Proj14/14.jpg";
import img_15p14 from "../assets/Proj14/15.jpg";
import img_16p14 from "../assets/Proj14/16.jpg";
import img_17p14 from "../assets/Proj14/17.jpg";
import img_18p14 from "../assets/Proj14/18.jpg";
import img_19p14 from "../assets/Proj14/19.jpg";
import img_20p14 from "../assets/Proj14/20.jpg";
import img_21p14 from "../assets/Proj14/21.jpg";
import img_22p14 from "../assets/Proj14/22.jpg";
import img_23p14 from "../assets/Proj14/23.jpg";
import img_24p14 from "../assets/Proj14/24.jpg";
import img_25p14 from "../assets/Proj14/25.jpg";
import img_26p14 from "../assets/Proj14/26.jpg";
import img_27p14 from "../assets/Proj14/27.jpg";
import img_28p14 from "../assets/Proj14/28.jpg";
import img_29p14 from "../assets/Proj14/29.jpg";
import img_30p14 from "../assets/Proj14/30.jpg";
import img_31p14 from "../assets/Proj14/31.jpg";
import img_32p14 from "../assets/Proj14/32.jpg";
import img_33p14 from "../assets/Proj14/33.jpg";
import img_34p14 from "../assets/Proj14/34.jpg";
import img_35p14 from "../assets/Proj14/35.jpg";
import img_36p14 from "../assets/Proj14/36.jpg";
import img_37p14 from "../assets/Proj14/37.jpg";

import img_1p15 from "../assets/Proj15/1.jpg";
import img_2p15 from "../assets/Proj15/2.jpg";
import img_3p15 from "../assets/Proj15/3.jpg";
import img_4p15 from "../assets/Proj15/4.jpg";
import img_5p15 from "../assets/Proj15/5.jpg";
import img_6p15 from "../assets/Proj15/6.jpg";
import img_7p15 from "../assets/Proj15/7.jpg";

import img_1p0 from "../assets/Proj0/1.jpg";

import img_1p16 from "../assets/Proj16/1.jpg";
import img_2p16 from "../assets/Proj16/2.jpg";
import img_3p16 from "../assets/Proj16/3.jpg";
import img_4p16 from "../assets/Proj16/4.jpg";
import img_5p16 from "../assets/Proj16/5.jpg";

import img_0p17 from "../assets/Proj17/cover.jpg";
import img_1p17 from "../assets/Proj17/1.jpg";
import img_2p17 from "../assets/Proj17/2.jpg";
import img_3p17 from "../assets/Proj17/3.jpg";
import img_4p17 from "../assets/Proj17/4.jpg";
import img_5p17 from "../assets/Proj17/5.jpg";
import img_6p17 from "../assets/Proj17/6.jpg";
import img_7p17 from "../assets/Proj17/7.jpg";
import img_8p17 from "../assets/Proj17/8.jpg";
import img_9p17 from "../assets/Proj17/9.jpg";
import img_10p17 from "../assets/Proj17/10.jpg";
import img_11p17 from "../assets/Proj17/11.jpg";
import img_12p17 from "../assets/Proj17/12.jpg";
import img_13p17 from "../assets/Proj17/13.jpg";
import img_14p17 from "../assets/Proj17/14.jpg";
import img_15p17 from "../assets/Proj17/15.jpg";

import img_1p18 from "../assets/Proj18/1.jpg";
import img_2p18 from "../assets/Proj18/2.jpg";

export interface CustomImage extends Image {
  original: string;
}

export const Proj0: CustomImage[] = [
  {
    src: img_1p0,
    original: img_1p0,
    height: window.innerHeight,
  },
]

export const Proj1: CustomImage[] = [
  {
    src: img_1,
    original: img_1,
    height: window.innerHeight,
  },
  {
    src: img_2,
    original: img_2,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3,
    original: img_3,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4,
    original: img_4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5,
    original: img_5,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6,
    original: img_6,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7,
    original: img_7,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_8,
    original: img_8,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_9,
    original: img_9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10,
    original: img_10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11,
    original: img_11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_12,
    original: img_12,
    height: window.innerHeight,
    width: "auto",
  },
  // {
  //   src: img_13,
  //   original: img_13,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
  // {
  //   src: img_14,
  //   original: img_14,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
  // {
  //   src: img_15,
  //   original: img_15,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
  // {
  //   src: img_18,
  //   original: img_18,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
  // {
  //   src: img_16,
  //   original: img_16,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
  // {
  //   src: img_17,
  //   original: img_17,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
];

export const Proj2: CustomImage[] = [
  {
    src: img_0p2,
    original: img_0p2,
    height: window.innerHeight,
  },
  {
    src: img_1p2,
    original: img_1p2,
    height: window.innerHeight,
  },
  {
    src: img_2p2,
    original: img_2p2,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p2,
    original: img_3p2,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p2,
    original: img_4p2,
    height: window.innerHeight,
    width: "auto",
  },
];

export const Proj3: CustomImage[] = [
  // {
  //   src: img_1p3,
  //   original: img_1p3,
  //   height: window.innerHeight,
  // },
  {
    src: img_6p3,
    original: img_6p3,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_2p3,
    original: img_2p3,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p3,
    original: img_3p3,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p3,
    original: img_4p3,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p3,
    original: img_5p3,
    height: window.innerHeight,
    width: "auto",
  },
  
];

export const Proj4: CustomImage[] = [
  // {
  //   src: img_1p4,
  //   original: img_1p4,
  //   height: window.innerHeight,
  // },
  {
    src: img_10p4,
    original: img_4p4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_2p4,
    original: img_2p4,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p4,
    original: img_3p4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p4,
    original: img_4p4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p4,
    original: img_5p4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p4,
    original: img_6p4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p4,
    original: img_1p4,
    height: window.innerHeight,
  },
  {
    src: img_8p4,
    original: img_2p4,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p4,
    original: img_3p4,
    height: window.innerHeight,
    width: "auto",
  },
 
  {
    src: img_11p4,
    original: img_5p4,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_12p4,
    original: img_6p4,
    height: window.innerHeight,
    width: "auto",
  },
];

export const Proj5: CustomImage[] = [
  {
    src: img_4p5,
    original: img_4p5,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_1p5,
    original: img_1p5,
    height: window.innerHeight,
  },
  {
    src: img_2p5,
    original: img_2p5,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p5,
    original: img_3p5,
    height: window.innerHeight,
    width: "auto",
  },
];
export const Proj6: CustomImage[] = [
  {
    src: img_10p6,
    original: img_10p6,
    height: window.innerHeight,
  },
  {
    src: img_9p6,
    original: img_9p6,
    height: window.innerHeight,
  },
  {
    src: img_11p6,
    original: img_11p6,
    height: window.innerHeight,
  },
  {
    src: img_12p6,
    original: img_12p6,
    height: window.innerHeight,
  },
  {
    src: img_1p6,
    original: img_1p6,
    height: window.innerHeight,
  },
  {
    src: img_2p6,
    original: img_2p6,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p6,
    original: img_3p6,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p6,
    original: img_4p6,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p6,
    original: img_5p6,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p6,
    original: img_6p6,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p6,
    original: img_1p6,
    height: window.innerHeight,
  },
  {
    src: img_8p6,
    original: img_2p6,
    height: window.innerHeight,
    width: 190,
  },
];

export const Proj7: CustomImage[] = [
  {
    src: img_1p7,
    original: img_1p7,
    height: window.innerHeight,
  },
  {
    src: img_2p7,
    original: img_2p7,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p7,
    original: img_3p7,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p7,
    original: img_4p7,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p7,
    original: img_5p7,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p7,
    original: img_6p7,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p7,
    original: img_1p7,
    height: window.innerHeight,
  },
  {
    src: img_8p7,
    original: img_2p7,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p7,
    original: img_3p7,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p7,
    original: img_4p7,
    height: window.innerHeight,
    width: "auto",
  },
]
export const Proj8: CustomImage[] = [
  {
    src: img_1p9,
    original: img_1p9,
    height: window.innerHeight,
  },
  {
    src: img_4p8,
    original: img_4p8,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_1p8,
    original: img_1p8,
    height: window.innerHeight,
  },
  {
    src: img_2p8,
    original: img_2p8,
    height: window.innerHeight,
    width: 190,
  },
  // {
  //   src: img_3p8,
  //   original: img_3p8,
  //   height: window.innerHeight,
  //   width: "auto",
  // },
  
  {
    src: img_5p8,
    original: img_5p8,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p8,
    original: img_6p8,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p8,
    original: img_1p8,
    height: window.innerHeight,
  },
  {
    src: img_8p8,
    original: img_2p8,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p8,
    original: img_3p8,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p8,
    original: img_4p8,
    height: window.innerHeight,
    width: "auto",
  },
// ]

// export const Proj9: CustomImage[] = [
  // {
  //   src: img_1p9,
  //   original: img_1p9,
  //   height: window.innerHeight,
  // },
  {
    src: img_2p9,
    original: img_2p9,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p9,
    original: img_3p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p9,
    original: img_4p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p9,
    original: img_5p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p9,
    original: img_6p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p9,
    original: img_1p9,
    height: window.innerHeight,
  },
  {
    src: img_8p9,
    original: img_2p9,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p9,
    original: img_9p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p9,
    original: img_10p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11p9,
    original: img_11p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_12p9,
    original: img_12p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_13p9,
    original: img_13p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_14p9,
    original: img_14p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_15p9,
    original: img_15p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_16p9,
    original: img_16p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_17p9,
    original: img_17p9,
    height: window.innerHeight,
  },
  {
    src: img_18p9,
    original: img_18p9,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_19p9,
    original: img_19p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_20p9,
    original: img_20p9,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_21p9,
    original: img_21p9,
    height: window.innerHeight,
    width: "auto",
  },
];

export const Proj10: CustomImage[] = [
  {
    src: img_0p10,
    original: img_0p10,
    height: window.innerHeight,
  },
  {
    src: img_1p10,
    original: img_1p10,
    height: window.innerHeight,
  },
  {
    src: img_2p10,
    original: img_2p10,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p10,
    original: img_3p10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p10,
    original: img_4p10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p10,
    original: img_5p10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p10,
    original: img_6p10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p10,
    original: img_1p10,
    height: window.innerHeight,
  },
  {
    src: img_8p10,
    original: img_2p10,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p10,
    original: img_3p10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p10,
    original: img_4p10,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11p10,
    original: img_11p10,
    height: window.innerHeight,
    width: "auto",
  },
]

export const Proj11: CustomImage[] = [
  {
    src: img_0p11,
    original: img_0p11,
    height: window.innerHeight,
  },
  {
    src: img_1p11,
    original: img_1p11,
    height: window.innerHeight,
  },
  {
    src: img_2p11,
    original: img_2p11,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p11,
    original: img_3p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p11,
    original: img_4p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p11,
    original: img_5p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p11,
    original: img_6p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p11,
    original: img_1p11,
    height: window.innerHeight,
  },
  {
    src: img_8p11,
    original: img_2p11,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p11,
    original: img_3p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p11,
    original: img_10p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11p11,
    original: img_11p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_12p11,
    original: img_12p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_13p11,
    original: img_13p11,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_14p11,
    original: img_14p11,
    height: window.innerHeight,
    width: "auto",
  },
]

export const Proj12: CustomImage[] = [
  {
    src: img_17p12,
    original: img_17p12,
    height: window.innerHeight,
  },
  {
    src: img_1p12,
    original: img_1p12,
    height: window.innerHeight,
  },
  {
    src: img_2p12,
    original: img_2p12,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p12,
    original: img_3p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p12,
    original: img_4p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p12,
    original: img_5p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p12,
    original: img_6p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p12,
    original: img_1p12,
    height: window.innerHeight,
  },
  {
    src: img_8p12,
    original: img_2p12,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p12,
    original: img_9p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p12,
    original: img_10p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11p12,
    original: img_11p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_12p12,
    original: img_12p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_13p12,
    original: img_13p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_14p12,
    original: img_14p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_15p12,
    original: img_15p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_16p12,
    original: img_16p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_18p12,
    original: img_18p12,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_19p12,
    original: img_19p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_20p12,
    original: img_20p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_21p12,
    original: img_21p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_22p12,
    original: img_22p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_23p12,
    original: img_23p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_24p12,
    original: img_24p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_25p12,
    original: img_25p12,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_26p12,
    original: img_26p12,
    height: window.innerHeight,
    width: "auto",
  },
];

export const Proj13: CustomImage[] = [
  {
    src: img_1p13,
    original: img_1p13,
    height: window.innerHeight,
  },
  {
    src: img_2p13,
    original: img_2p13,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p13,
    original: img_3p13,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p13,
    original: img_4p13,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p13,
    original: img_5p13,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p13,
    original: img_6p13,
    height: window.innerHeight,
    width: "auto",
  },
]

export const Proj14: CustomImage[] = [
  {
    src: img_12p14,
    original: img_12p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_1p14,
    original: img_1p14,
    height: window.innerHeight,
  },
  {
    src: img_2p14,
    original: img_2p14,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p14,
    original: img_3p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p14,
    original: img_4p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p14,
    original: img_5p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p14,
    original: img_6p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p14,
    original: img_1p14,
    height: window.innerHeight,
  },
  {
    src: img_8p14,
    original: img_2p14,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p14,
    original: img_9p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p14,
    original: img_10p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11p14,
    original: img_11p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_13p14,
    original: img_13p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_14p14,
    original: img_14p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_15p14,
    original: img_15p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_16p14,
    original: img_16p14,
    height: window.innerHeight,
  },
  {
    src: img_17p14,
    original: img_17p14,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_18p14,
    original: img_18p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_19p14,
    original: img_19p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_20p14,
    original: img_20p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_21p14,
    original: img_21p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_22p14,
    original: img_22p14,
    height: window.innerHeight,
  },
  {
    src: img_23p14,
    original: img_23p14,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_24p14,
    original: img_24p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_25p14,
    original: img_25p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_26p14,
    original: img_26p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_27p14,
    original: img_27p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_28p14,
    original: img_28p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_29p14,
    original: img_29p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_30p14,
    original: img_30p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_31p14,
    original: img_31p14,
    height: window.innerHeight,
  },
  {
    src: img_32p14,
    original: img_32p14,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_33p14,
    original: img_33p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_34p14,
    original: img_34p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_35p14,
    original: img_35p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_36p14,
    original: img_36p14,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_37p14,
    original: img_37p14,
    height: window.innerHeight,
  },
]

export const Proj15: CustomImage[] = [
  {
    src: img_1p15,
    original: img_1p15,
    height: window.innerHeight,
  },
  {
    src: img_2p15,
    original: img_2p15,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p15,
    original: img_3p15,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p15,
    original: img_4p15,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p15,
    original: img_5p15,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p15,
    original: img_6p15,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p15,
    original: img_1p15,
    height: window.innerHeight,
  },
]

export const Proj16: CustomImage[] = [
  {
    src: img_2p16,
    original: img_2p16,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p16,
    original: img_3p16,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p16,
    original: img_4p16,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p16,
    original: img_5p16,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_1p16,
    original: img_1p16,
    height: window.innerHeight,
  },
]

export const Proj17: CustomImage[] = [
  {
    src: img_0p17,
    original: img_0p17,
    height: window.innerHeight,
  },
  {
    src: img_1p17,
    original: img_1p17,
    height: window.innerHeight,
  },
  {
    src: img_2p17,
    original: img_2p17,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_3p17,
    original: img_3p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_4p17,
    original: img_4p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_5p17,
    original: img_5p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_6p17,
    original: img_6p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_7p17,
    original: img_1p17,
    height: window.innerHeight,
  },
  {
    src: img_8p17,
    original: img_2p17,
    height: window.innerHeight,
    width: 190,
  },
  {
    src: img_9p17,
    original: img_3p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_10p17,
    original: img_10p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_11p17,
    original: img_11p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_12p17,
    original: img_12p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_13p17,
    original: img_13p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_14p17,
    original: img_14p17,
    height: window.innerHeight,
    width: "auto",
  },
  {
    src: img_15p17,
    original: img_15p17,
    height: window.innerHeight,
  },
]

export const Proj18: CustomImage[] = [
  {
    src: img_1p18,
    original: img_1p18,
    height: window.innerHeight,
  },
]
export const Proj19: CustomImage[] = [
  {
    src: img_2p18,
    original: img_2p18,
    height: window.innerHeight,
  },
]