import React, { useState, useEffect } from "react";
import "./aboutme.scss";
import "./tabletbutton.sass";
import { useLocation } from "react-router-dom";
import { scroll1, scroll2, scroll3, scroll4, scroll5 } from "./ScrollDataList";
import click1 from "./assets/click5.mp3";
import { useMediaQuery } from 'react-responsive'

function Scrolls() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 483px)' })
  const params = useLocation();
  let audio = new Audio(click1);
  const [getscrollList, setScrolllist] = useState([]);
  const scrolls = { scroll1: scroll1, scroll2: scroll2,scroll3:scroll3, scroll4:scroll4, scroll5:scroll5 };
  useEffect(() => {
    for (const key in scrolls) {
      if (`${key}` === params.state.item.name) {
        setScrolllist(scrolls[params.state.item.name]);
      }
    }
  });
  const [getImage, setImage] = useState(params.state.item.default);
  function handleclick1(e, item) {
    audio.play();
    setImage(item.image);
  }
  return (
    <>
      <div style={{ display: "flex", background: "black", flexDirection : isTabletOrMobile && "column", height:isTabletOrMobile && window.innerHeight }}>
        <div
          style={{
            width: isTabletOrMobile ? "100%" : "80%",
            height:isTabletOrMobile ? "80%" : window.innerHeight,
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <img
            style={{
              height:isTabletOrMobile ? "80%"  :window.innerHeight,
              width: "auto",
              resize: "block",
              alignSelf : isTabletOrMobile && "center"
            }}
            src={getImage}
          />
        </div>
        <div className="buttoncontainer">
          {getscrollList.map((item) => {
            return (
              <div className="toggle">
                <input type="checkbox" onClick={(e) => handleclick1(e, item)} />
                <span className="button"></span>
                <span className="label" style={{fontFamily:'futur'}}>{item.lable}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Scrolls;
