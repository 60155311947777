import React from "react";
import "./aboutme.scss";

import Circle from "./assets/About_me.png";
import Image from "react-bootstrap/Image";
import "./AboutmeAnimation.css";
import PDF from "./assets/Yathish_Resume.pdf";
import { useMediaQuery } from 'react-responsive'

function AboutMe() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 483px)' })
  return (
    <div
      style={{
        backgroundColor: "white",
        height: window.innerHeight,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: isTabletOrMobile ? "column" : "row",
          height: "80%",
        }}
      >
        <div
          style={{
            width: isTabletOrMobile ? "100%" : "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 20 }}>
            <Image src={Circle} style={{ height: isTabletOrMobile ? 260 : 360 }} alt="" />
          </div>
          <div>
            <p
              style={{
                fontSize: isTabletOrMobile ? 40 : 60,
                fontFamily: "Bold",
                color: "black",
                textAlign: "start",
                margin: "auto",
              }}
            >
              YATHISH YOGISH
            </p>
            <a href={
              "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sendmailtoyathish@gmail.com"
            }
              style={{ textDecoration: 'none', fontFamily: 'futur' }}
              target="_blank"
              rel="noreferrer">
              <p style={{ color: 'black', }}>Contact : sendmailtoyathish@gmail.com</p>
            </a>
          </div>
        </div>
        <div
          style={{
            height: isTabletOrMobile ? 1 : "auto",
            width: isTabletOrMobile ? "auto" : 1,
            marginBottom: 20,
            marginTop: 20,
            marginLeft: isTabletOrMobile && 20,
            marginRight: isTabletOrMobile && 20,
            background: "black",
          }}
        />

        <div
          style={{
            width: isTabletOrMobile ? "90%" : "60%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            margin: 20,
            marginRight: 20,
            marginTop: isTabletOrMobile && 0,
            //  overflow:isTabletOrMobile&& "auto",
            marginBottom: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: isTabletOrMobile ? 10 : 60,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: 40,
                fontFamily: "Bold",
                color: "black",
                textAlign: "left",
                // margin: "auto",
                // marginTop: 60,
                flex: 0.8,
              }}
            >
              About me
            </p>
            <a href={PDF}>
              <p
                style={{
                  display: 'flex',
                  alignSelf: 'baseline',
                  fontSize: 14,
                  fontFamily: "futur",
                  color: "black",
                  textAlign: "start",
                  // margin: "auto",
                  // marginRight: 20,
                  flex: 0.2,
                }}
              >
                Download Resume
              </p>
            </a>
          </div>
          <div style={{ fontFamily: "futur", marginTop: isTabletOrMobile ? 20 : 40, overflow: "auto", height: "auto" }}>
          <p >
            Yathish stepped into the design field while pursuing his bachelor's
            degree in architecture from the B.M.S. School of Architecture in
            Bangalore, India. While he was always enamored with the presence of
            light in all the projects he worked on as a student, the design
            language that infused light into architecture also captivated him.
            <br />
            <br />
            Yathish was enamored with the way lighting design followed and
            incorporated light into architectural structures while practicing
            architecture in India. He found the design language that lighting
            designers used and how they collaborated with architects to make
            their designs a reality to be fascinating. This fascination led him
            to pursue a career in lighting design, where he continues to explore
            the interplay between light and space to create beautiful and
            functional designs.
            <br />
            <br />
            He discovered his passion for lighting design by pursuing a master's
            degree at the KTH Royal Institute of Technology in Stockholm,
            Sweden, to understand lighting design better. He recognized its
            crucial role in creating a comfortable environment, striving to
            develop well-lit spaces, and using light as a design element,
            enhancing the mood and atmosphere of the area. <br />
            <br />
            As a pragmatic architect specializing in lighting design, his
            approach to architecture is informed by function and aesthetics.
            Good design should look beautiful and serve a purpose by solving
            problems and improving the lives of those who use the space. My
            inclination towards minimalism in architecture is also reflected in
            my work. Less is often more, and that simplicity can be a powerful
            design tool. <br />
            <br />
            Additionally, I had the opportunity to work on the lighting design
            for the FIFA 2022 World Cup, which allowed me to develop a deep
            understanding of the technical aspects of lighting design and
            project management.
            <br />
            <br /> Overall, my approach to architecture and lighting design is
            grounded in practicality and a deep appreciation for the role of
            design in improving the world around us. Whether working on a
            large-scale commercial project or a small residential renovation, I
            bring the same passion and expertise to every project, always
            striving to create beautiful and functional spaces. In addition, I
            am strongly dedicated to incorporating LEED principles for
            sustainability and energy efficiency into designs wherever possible.
            <br />
            <br />
            Yathish is currently enrolled as a Junior Associate in the IALD
            (International Association of Lighting Designers).
          </p>
          </div>
        </div>

        {/* <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">L</span>
          <span class="loading-text-words">O</span>
          <span class="loading-text-words">A</span>
          <span class="loading-text-words">D</span>
          <span class="loading-text-words">I</span>
          <span class="loading-text-words">N</span>
          <span class="loading-text-words">G</span>
        </div>
      </div> */}
        {/* <div className="wrapper">
        <div className="container">
          <p data-typing="i">
            I
          </p>
          <a
            href="https://github.com/daybrush"
            target="_blank"
            data-typing="frontend"
          >
            'm Front-End
          </a>
          <a
            href="https://github.com/daybrush"
            target="_blank"
            data-typing="engineer"
          >
            Engineer
          </a>
          <p data-typing="with">with</p>
          <p data-typing="javascript">JavaScript</p>
          <p data-typing="typescript">TypeScript</p>
          <p data-typing="css">CSS</p>
          <p data-typing="nodejs">Node.js</p>
          <p data-typing="animation">Animation</p>
          <a
            href="https://github.com/daybrush/scenejs"
            target="_blank"
            data-typing="scenejs"
          >
            Scene.js
          </a>
        </div>
      </div> */}
      </div>
      {!isTabletOrMobile && <div className="Animationbody" style={{ height: "20%" }}>
        <div className="caption">
          <div className="text-box">
            <div>
              Curiosity is a fascinating spark – Once ignited in oneself, it can
              light the way through infinite possibilities
            </div>
            <div>I promote inclusion with distinctive illumination</div>
            {/* <div>experiences</div> */}
          </div>
        </div>
      </div>}
    </div>
  );
}

export default AboutMe;
